import type DataColumns from './DataGrid.DataColumns.ts';
import type DataColumn from './DataGrid.DataColumn.ts';
import DataColumnGroup from './DataGrid.DataColumnGroup.ts';

export default class ColumnMove {

    container: HTMLElement;
    dataColumns: DataColumns;
    prevX: number | null;
    movedColumn: any;
    overColumnField: string | null;
    orderedColumns: OrderedColumnPolyfill[];
    moveToField: string | null;
    movedElement: any;
    // columnCache: Record<string, HTMLElement[]>;
    columnCache: Record<string, CachedColumn|CachedColumnGroup>;
    prevIndex: number;
    prevDirection: any;
    prevMove: any;
    dragImage?: HTMLElement;
    dragImageLabel?: HTMLElement;
    isMultilineHeader: boolean;
    isDragging = false;
    private _computedGroups?: DataColumnGroup[][];

    get groupPrefix() { return 'o365_group'; }

    constructor(container: HTMLElement, columns: DataColumns, isMultilineHeader: boolean) {
        this.container = container;
        this.dataColumns = columns;
        this.prevX = null;
        this.movedColumn = null;
        this.overColumnField = null;
        this.orderedColumns = [];
        this.moveToField = null;
        this.isMultilineHeader = isMultilineHeader ?? false;

        this.movedElement = null;

        this._onDragEnd = this._onDragEnd.bind(this);

        this.columnCache = {};
        // this.dragImage = document.createElement("div");
        // this.dragImage.classList.add("grid-drag-image");
        // this.dragImage.id = "gridColumnDragImage";
        this.initDragImage();
        this.prevIndex = 0;
        this.prevDirection = null;
        this.prevMove = null;
        this.init();
    }


    initDragImage() {
        this.dragImage = document.createElement('div');
        this.dragImage.id = "gridColumnDragImage";
        this.dragImage.classList.add('o365-grid-drag-image', 'rounded-2');
        const labelContainer = document.createElement('div');
        labelContainer.classList.add('drag-image-label-container', 'text-truncate')
        this.dragImageLabel = document.createElement('span');
        this.dragImageLabel.classList.add('text-truncate');

        this.dragImage.append(labelContainer);
        // const dragIcon = document.createElement('i');
        // dragIcon.classList.add('bi', 'bi-arrows-move', 'me-2', 'ms-1')
        // labelContainer.append(dragIcon);
        labelContainer.append(this.dragImageLabel);
    }

    cacheColumns() {
        this.columnCache = {};
        this.orderedColumns = [];

        this.dataColumns.columns.forEach(col => {
            if (!col.hide) {
                this.columnCache[col.colId] = new CachedColumn(col.colId, Array.from(this.container.querySelectorAll("[o365-field='" + col.colId + "'][data-o365-colindex]")), col.width + col.widthAdjustment)
                const element: HTMLElement = this.container.querySelector(`.o365-header-cell.o365-header-cell-container[o365-field="${col.colId}"][data-o365-colindex]`)!;
                element.dataset.left = col.left as any;
                this.orderedColumns.push({
                    field: col.colId,
                    order: col.order,
                    width: col.width + col.widthAdjustment,
                    pinned: col.pinned ? (col.pinned as any) : null,
                    title: col.headerName ?? col.field,

                    parentGroupId: col.parentGroupId,
                    element: element,
                    get left() { return parseInt(this.element.style.left) || parseInt(this.element.dataset.left) },
                })
            } else {
                this.orderedColumns.push({
                    field: col.colId,
                    order: col.order,
                    pinned: col.pinned ? (col.pinned as any) : null,
                    title: col.headerName ?? col.field,
                    parentGroupId: col.parentGroupId,
                    hide: true
                });
            }
        });

        if (this.isMultilineHeader) {
            this._addMultilineStyles();
        }
    }
    shouldDebug =false;
    renderGroups() {
        Object.entries(this.columnCache).forEach(([key, col]) => {
            if (col.type == 'group') {
                delete this.columnCache[key];
            }
        });
        
        const computedGroups = [...(this.dataColumns.columnGroups!)].reverse().reduce((groupRows, row, rowIndex) => {
            const previousRow = groupRows[rowIndex - 1] ?? this.orderedColumns;

            const groupIdFromCol = (col: DataColumn) => {
                return `${col.parentGroupId}-_-${col.pinned??'none'}`
                // return `${col.parentGroupId}${col.pinned}`
            };

            const computedGroupRow = previousRow.reduce((groups: any, col: any) => {
                if (col.hide) { return groups; }
                if (col.parentGroupId) {
                    const lastGroup = groups[groups.length - 1];
                    if (lastGroup?.groupId) {
                        // if (lastGroup.groupId === col.parentGroupId) {
                        if (lastGroup.groupId === groupIdFromCol(col)) {
                            lastGroup.children.push(col);
                            return groups;
                        }
                    }
                    const initialGroup = row.find(x => x.groupId === col.parentGroupId);
                    if (!initialGroup) {
                        groups.push(col);
                        return groups;
                    }
                    const group = new DataColumnGroup({
                        // groupId: initialGroup.groupId,
                        groupId: groupIdFromCol(col),
                        children: [col],
                        headerName: initialGroup.headerName ?? initialGroup.headername,
                        parentGroupId: initialGroup.parentGroupId
                    });
                    groups.push(group);
                } else {
                    groups.push(col);
                }
                return groups;
            }, [] as DataColumnGroup[]);

            groupRows.push(computedGroupRow);
            return groupRows
        }, [] as DataColumnGroup[][]).reverse();
        this._computedGroups = computedGroups;

        computedGroups.forEach((groupRow, index) => {

            const row = this.container.querySelector(`.pinned-none.o365-header-group-row-cache[data-o365-rowindex="${index}"]`)
            const rowLeft = this.container.querySelector(`.pinned-left.o365-header-group-row-cache[data-o365-rowindex="${index}"]`)
            const rowRight = this.container.querySelector(`.pinned-right.o365-header-group-row-cache[data-o365-rowindex="${index}"]`)
            if (row == null) { return; }
            Array.from(row.children).forEach(el => el.remove());
            if (rowLeft) {
                Array.from(rowLeft.children).forEach(el => el.remove());
            }
            if (rowRight) {
                Array.from(rowRight.children).forEach(el => el.remove());
            }
            groupRow.forEach((column, colIndex) => {
                const node = document.createElement('div');
                node.classList.add('o365-header-group-cell', 'o365-header-cell');
                if (column.groupId) {
                    node.classList.add('group-cell');
                    const uid = `${this.groupPrefix}_${index}_${colIndex}`
                    const mapChildren = (pArr: Array<DataColumn | DataColumnGroup>): (CachedColumn | CachedColumnGroup)[] => {
                        return pArr.map(col => {
                            if (col instanceof DataColumnGroup) {
                                const groupId = `${col.groupId.split('-_-')[0]}-${col.order}`;
                                if (this.columnCache[groupId]) {
                                    return this.columnCache[groupId];
                                } else {
                                    return new CachedColumnGroup(groupId, mapChildren(col.children))
                                }
                            } else {
                                return this.columnCache[col.colId];
                            }
                        });
                    }
                    this.columnCache[uid] = new CachedColumnGroup(uid, mapChildren(column.children))
                }
                const span = document.createElement('span');
                span.classList.add('o365-header-cell-text', 'text-truncate');
                span.innerText = column.groupId ? column.headerName : '';
                node.append(span);
                node.style.left = column.left + 'px';
                node.style.width = column.width + 'px';
                if (column.pinned === 'left') {
                    rowLeft?.append(node);
                } else if (column.pinned === 'right') {
                    rowRight?.append(node);
                } else {
                    row.append(node);
                }
            });
        });
    }

    setClassForCachedColumns(pColumnName: string, pClassName: string, pRemove?: boolean) {
        if (!pRemove) {
            this.columnCache[pColumnName]?.addClass(pClassName);
            // this.columnCache[pColumnName].forEach(el => {
            //     el.classList.add(pClassName);
            // });
        } else {
            this.columnCache[pClassName]?.removeClass(pClassName);
            // this.columnCache[pColumnName].forEach(el => {
            //     el.classList.remove(pClassName);
            // });
        }
    }

    setLeftProp(pColumnName: string, pLeft: number) {
        if (this.columnCache[pColumnName]) {
            this.columnCache[pColumnName].left = pLeft;
        }
        // if (this.columnCache[pColumnName].length === 0) return;
        // if (this.columnCache[pColumnName][0].style.left === pLeft.toString() + "px") return;
        // this.columnCache[pColumnName].forEach(el => {
        //     el.style.left = pLeft + "px";
        //     el.dataset.left = `${pLeft}`;
        // });
    }


    moveColumn() {
        const vColumnToIndex = this.orderedColumns.findIndex(x => x.field === this.overColumnField);
        const vColumnFromIndex = this.orderedColumns.findIndex(x => x.field === this.movedColumn.field);
        //if(vColumnToIndex === vColumnFromIndex) return;

        let vSumLeft = 0, vSum = 0, vSumRight = 0;
        this.orderedColumns.splice(vColumnFromIndex, 1);
        this.movedColumn.order = vColumnToIndex;
        this.orderedColumns.splice(vColumnToIndex, 0, this.movedColumn);

        this.orderedColumns.forEach(col => {
            if (col.hide) { return; }
            // col.order = index;
            if (col.pinned === 'left') {
                this.setLeftProp(col.field, vSumLeft);
                vSumLeft += col.width ?? 0;
            } else if (col.pinned === 'right') {
                this.setLeftProp(col.field, vSumRight);
                vSumRight += col.width ?? 0;
            } else {
                this.setLeftProp(col.field, vSum);
                vSum += col.width ?? 0;
            }
        });

        if (this.dataColumns.hasGroupedColumns) {
            this.renderGroups();
        }
    }

    init() {
        // this.container.addEventListener('dragstart', async (evt) => {
        //     if (!evt.target.getAttribute("o365-header-group-id")) { return; }
        //     this.movedElement = evt.target;
        //     const group = this.movedElement.getAttribute('o365-header-group');
        //     if (!group || !this.dataColumns.collectGroup) { return; }
        //     await this.dataColumns.collectGroup(group);
        // }, false);

        this.container.addEventListener("dragstart", pEvent => {
            const target = <HTMLElement>pEvent.target;
            const vClosest = target?.closest ? <HTMLElement>target?.closest("[o365-field][data-o365-colindex]") : null;
            if (!vClosest || !vClosest.classList.contains('o365-header-cell')) {
                if (target.getAttribute('o365-header-group')) {
                    return;
                    this._handleGroupDragStart(pEvent);
                }
                return;
            }
            // if (!vClosest || !vClosest.classList.contains('o365-header-cell')) { evt.preventDefault(); return false; }
            this.movedElement = pEvent.target;

            this.cacheColumns();
            if (pEvent.dataTransfer == null) { return; }
            const colId = (pEvent.target as HTMLElement).getAttribute('o365-field');
            if (colId == null) { return; }
            pEvent.dataTransfer.effectAllowed = "all";
            pEvent.dataTransfer.clearData();
            pEvent.dataTransfer.setData('text/plain', colId);
            pEvent.dataTransfer.setData('o365-nt/group-by-column', JSON.stringify({ colId: colId, type: 'column' }));
            this.prevX = pEvent.clientX;

            // evt.target.style.cursor = 'pointer !important';

            if (this.dataColumns.hasGroupedColumns) {
                this.container.querySelectorAll('.o365-header-group-row').forEach(row => row.classList.add('d-none'));

                const createRowNodes = (pinned = 'none') => {
                    const rowNodes = this.dataColumns.columnGroups!.map((_group, index) => {
                        const node = document.createElement('div');
                        node.dataset.o365Rowindex = index + '';
                        node.classList.add('o365-header-row', 'o365-header-group-row', 'o365-header-group-row-cache', `pinned-${pinned}`);
                        return node;
                    });
                    return rowNodes;
                };
                this.container.querySelector('.o365-header-viewport .o365-grid-container')?.prepend(...createRowNodes());
                this.container.querySelector('.o365-header-pinned-left')?.prepend(...createRowNodes('left'));
                this.container.querySelector('.o365-header-pinned-right')?.prepend(...createRowNodes('right'));

                this.renderGroups();
            }

            this.container.classList.add('o365-columns-moving');

            this.movedColumn = this.orderedColumns.find(x => x.field === (pEvent.target as HTMLElement).getAttribute("o365-field"));
            this.movedColumn.initialOrder = this.movedColumn.order;

            // this.dragImage.textContent = this.movedColumn.title;
            this.dragImageLabel!.textContent = this.movedColumn.title;
            this.dragImage!.style.width = this.movedColumn.width + 'px';
            document.body.appendChild(this.dragImage!);
            pEvent.dataTransfer.setDragImage(this.dragImage!, 0, 0);
            pEvent.dataTransfer.setData('o365-nt/column-order', JSON.stringify({ colId: this.movedColumn.field, initialOrder: this.movedColumn.order }));

            this.setClassForCachedColumns(this.movedColumn.field, "column-moving");

            window.addEventListener('dragend', this._onDragEnd);
            this.isDragging = true;

        }, false);

        this.container.addEventListener("dragenter", evt => {
            if (!this._isOrderEvent(evt)) { return; }
            if (evt.dataTransfer == null) { return; }
            evt.dataTransfer.dropEffect = "copy";

        });

        this.container.addEventListener('dragover', (pEvent) => {
            if (!this._isOrderEvent(pEvent)) { return; }
            pEvent.preventDefault();

            const target = <HTMLElement>pEvent.target;
            const closestCell = <HTMLElement>target.closest('[o365-field][data-o365-colindex]');
            // if (!closestCell) { return; }
            if (!closestCell || !closestCell.classList.contains('o365-header-cell')) { return; }

            if (closestCell === this.movedElement) { return; }

            if (this.prevX === pEvent.clientX) { return; }

            const targetIndex = parseInt(closestCell?.dataset.o365Colindex ?? '');
            const fieldName = closestCell.getAttribute('o365-field');
            if (!fieldName) { return; }

            let orderDirection: string | null = null;

            if (pEvent.clientX > (this.prevX ?? 0)) {
                orderDirection = 'right';
            } else {
                orderDirection = 'left';
            }

            const directionChanged = orderDirection !== this.prevDirection;
            if (!directionChanged && targetIndex === parseInt(this.movedElement.dataset.o365Colindex)) {
                return;
            }

            if (targetIndex === 0 || targetIndex >= this.orderedColumns.length - 1) {
                if (pEvent.dataTransfer == null) { return; }
                pEvent.dataTransfer.dropEffect = 'none';
                return;
            }

            const column = this.orderedColumns.find(x => x.field === fieldName);
            if (!column) {
                return;
            }

            // if (this.movedColumn.pinned != column.pinned || (this.movedColumn.parentGroupId != column.parentGroupId)) {
            if (this.movedColumn.pinned != column.pinned) {
                if (pEvent.dataTransfer == null) { return; }
                pEvent.dataTransfer.dropEffect = 'none';
                return;
            }

            if (this.overColumnField === fieldName && this.prevDirection === orderDirection) { return; }

            this.overColumnField = fieldName;

            this.moveColumn();
            this.prevDirection = orderDirection;
            this.prevX = pEvent.clientX;
        }, false);

        this.container.addEventListener("drop", evt => {
            if (!this._isOrderEvent(evt)) { return; }
            if (this.dataColumns.hasGroupedColumns) {
                this.container.querySelectorAll('.o365-header-group-row-cache').forEach(row => row.remove());
                this.container.querySelectorAll('.o365-header-group-row').forEach(row => row.classList.remove('d-none'));
            }
            if (!this.movedColumn) return;
            window.removeEventListener('dragend', this._onDragEnd);

            this.container.classList.remove('o365-columns-moving');

            this.setClassForCachedColumns(this.movedColumn.field, "column-moving", true);


            this.orderedColumns.forEach((col, index) => {
                const isMovedCol = this.movedColumn.field === col.field;
                if (isMovedCol) {
                    // if (this.movedColumn.initialOrder < index) {
                    //     const referenceCol = this.orderedColumns[index-1];
                    //     console.log(this.dataColumns.getColumn(referenceCol.field).order, referenceCol.field);
                    // } else {
                    //     const referenceCol = this.orderedColumns[index+1];
                    //     console.log(this.dataColumns.getColumn(referenceCol.field).order, referenceCol.field);
                    // }
                    this.dataColumns.getColumn(col.field)?.trackChange('order', index);
                }
                this.dataColumns.setColumnProperty(col.field, "order", index);
            })
            if (this.isMultilineHeader) {
                this._clearMultilineStyles();
            }

            this.movedColumn = null;
            this.orderedColumns = [];
            this.overColumnField = null;
            this.dragImage?.remove();
            this.moveToField = null;
            this.columnCache = {};
            this.prevMove = null;
            this.prevDirection = null;
            this.isDragging = false;
        }, false);

    }

    private _onDragEnd = (e: DragEvent) => {
        if (!this._isOrderEvent(e)) { return; }
        window.removeEventListener('dragend', this._onDragEnd);

        if (this.dataColumns.hasGroupedColumns) {
            this.container.querySelectorAll('.o365-header-group-row-cache').forEach(row => row.remove());
            this.container.querySelectorAll('.o365-header-group-row').forEach(row => row.classList.remove('d-none'));
        }
        if (!this.movedColumn) return;

        this.container.classList.remove('o365-columns-moving');

        this.setClassForCachedColumns(this.movedColumn.field, "column-moving", true);

        this.orderedColumns.forEach((col, index) => {
            const isMovedCol = this.movedColumn.field === col.field;
            if (isMovedCol) {
                this.dataColumns.getColumn(col.field)?.trackChange('order', index);
                //this.dataColumns.setColumnOrder(this.dataColumns.getColumn(col.field), index, true, true);
            }
            this.dataColumns.setColumnProperty(col.field, "order", index);
        });
        if (this.isMultilineHeader) {
            this._clearMultilineStyles();
        }


        this.movedColumn = null;
        this.orderedColumns = [];
        this.overColumnField = null;
        this.dragImage?.remove();
        this.moveToField = null;
        this.columnCache = {};
        this.prevMove = null;
        this.isDragging = false;
    }

    cancelColumnMove(skipOrderSet = false) {
        window.removeEventListener('dragend', this._onDragEnd);

        if (this.dataColumns.hasGroupedColumns) {
            this.container.querySelectorAll('.o365-header-group-row-cache').forEach(row => row.remove());
            this.container.querySelectorAll('.o365-header-group-row').forEach(row => row.classList.remove('d-none'));
        }
        if (!this.movedColumn) return;

        this.container.classList.remove('o365-columns-moving');

        this.setClassForCachedColumns(this.movedColumn.field, "column-moving", true);

        if (!skipOrderSet) {
            this.orderedColumns.forEach((col, index) => {
                this.dataColumns.setColumnProperty(col.field, "order", index);
            })
        }
        if (this.isMultilineHeader) {
            this._clearMultilineStyles();
        }

        this.movedColumn = null;
        this.orderedColumns = [];
        this.overColumnField = null;
        this.dragImage?.remove();
        this.moveToField = null;
        this.columnCache = {};
        this.prevMove = null;
        this.isDragging = false;
    }

    private _addMultilineStyles() {
        let rowHeight = Object.values(this.columnCache).reduce((height: number, column) => {
            if (column.type != 'column') { return height; }
            const elements = column.elements;
            const parsedHeight = elements[0].parentElement?.clientHeight;
            if (parsedHeight != null && parsedHeight > height) { height = parsedHeight; }
            return parsedHeight ?? 34;
        }, 34)
        Object.values(this.columnCache).forEach(col => {
            if (col.type != 'column') { return; }
            col.elements[0].style.height = `${rowHeight}px`;
            if (col.elements[0].parentElement) {
                col.elements[0].parentElement.style.height = `${rowHeight}px`;
            }
            const column = this.dataColumns.getColumn(col.id);
            requestAnimationFrame(() => {
                col.elements[0].style.position = 'absolute';
                col.elements[0].style.left = `${column?.left}px`;
            });
        });

        // Object.entries(this.columnCache).forEach(([key, elements]) => {
        //     elements[0].style.height = rowHeight + 'px';
        //     if (elements[0].parentElement) {
        //         elements[0].parentElement.style.height = rowHeight + 'px';
        //     }
        //     const column = this.dataColumns.getColumn(key);
        //     window.requestAnimationFrame(() => {
        //         elements[0].style.position = 'absolute'
        //         elements[0].style.left = column?.left + 'px';
        //     });
        // });
    }

    private _clearMultilineStyles() {
        Object.values(this.columnCache).forEach((column) => {
            if (column.type != 'column') { return; }
            const elements = column.elements;
            if (elements[0].parentElement) {
                elements[0].parentElement.style.height = '';
            }
            elements[0].style.height = '';
            elements[0].style.left = '';
            elements[0].style.position = '';
        });
    }

    private _isOrderEvent(pEvent: DragEvent) {
        return pEvent.dataTransfer != null && pEvent.dataTransfer.types.includes('o365-nt/column-order')
    }

    private _handleGroupDragStart(pEvent: DragEvent) {
        if (pEvent.dataTransfer == null) { return; }
        const target = pEvent.target as HTMLElement;
        const groupId = target.getAttribute('o365-header-group-id');
        this.movedElement = target;
        const groupLocation = this.movedElement.getAttribute('o365-header-group') as string;
        if (!groupLocation) { return; }
        this.cacheColumns();
        // this.renderGroups();

        // const cachedGroup = this.columnCache[`${this.groupPrefix}_${location[0]}_${location[1]}`];
        // debugger;
        const location = groupLocation.split('-').map(x => +x);
        const groupOptions = this._computedGroups?.at(location[0])?.at(location[1]);
        // if (groupOptions == null) { return; }

        this.container.querySelectorAll('.o365-header-group-row').forEach(row => row.classList.add('d-none'));

        const createRowNodes = (pinned = 'none') => {
            const rowNodes = this.dataColumns.columnGroups!.map((_group, index) => {
                const node = document.createElement('div');
                node.dataset.o365Rowindex = index + '';
                node.classList.add('o365-header-row', 'o365-header-group-row', 'o365-header-group-row-cache', `pinned-${pinned}`);
                return node;
            });
            return rowNodes;
        };
        this.container.querySelector('.o365-header-viewport .o365-grid-container')?.prepend(...createRowNodes());
        this.container.querySelector('.o365-header-pinned-left')?.prepend(...createRowNodes('left'));
        this.container.querySelector('.o365-header-pinned-right')?.prepend(...createRowNodes('right'));

        this.renderGroups();

        const cachedGroupId = `${this.groupPrefix}_${location[0]}_${location[1]}`;
        const cachedGroup = this.columnCache[cachedGroupId];

        // const firstColumn = groupOptions.children[0];
        // // let order = this.orderedColumns.findIndex(col => col.field === firstColumn.field);
        // // // this.orderedColumns.forEach((column, index) => {
        // // //     if (column.parentGroupId === firstColumn.parentGroupId) {
        // // //         this._moveColumnFromTo(index, order);
        // // //         order++;
        // // //     }
        // // // });

        pEvent.dataTransfer.effectAllowed = "all";
        pEvent.dataTransfer.clearData();
        pEvent.dataTransfer.setData('text/plain', groupOptions?.caption ?? cachedGroup.id);
        this.prevX = pEvent.clientX;

        this.container.classList.add('o365-columns-moving');

        this.movedColumn = this.orderedColumns.find(x => x.field === (pEvent.target as HTMLElement).getAttribute("o365-field"));
        this.movedColumn.initialOrder = this.movedColumn.order;

        this.dragImageLabel!.textContent = this.movedColumn.title;
        this.dragImage!.style.width = this.movedColumn.width + 'px';
        document.body.appendChild(this.dragImage!);
        pEvent.dataTransfer.setDragImage(this.dragImage!, 0, 0);
        pEvent.dataTransfer.setData('o365-nt/column-order', JSON.stringify({ colId: this.movedColumn.field, initialOrder: this.movedColumn.order }));

        this.setClassForCachedColumns(this.movedColumn.field, "column-moving");
        window.addEventListener('dragend', this._onDragEnd);
    }

    private _moveColumnFromTo(pFromIndex: number, pToIndex: number) {
        if (pFromIndex === pToIndex) { return; }
        const movedColumn = this.orderedColumns[pFromIndex];
        const targetColumn = this.orderedColumns[pToIndex];
        console.log(movedColumn, targetColumn);
        let sumLeft = 0;
        let sum = 0;
        let sumRight = 0;

        this.orderedColumns.splice(pFromIndex, 1);
        movedColumn.order = pToIndex;
        this.orderedColumns.splice(pToIndex, 0, movedColumn);

        this.orderedColumns.forEach(col => {
            if (col.hide) { return; }
            if (col.pinned === 'left') {
                this.setLeftProp(col.field, sumLeft);
                sumLeft += col.width ?? 0;
            } else if (col.pinned === 'right') {
                this.setLeftProp(col.field, sumRight);
                sumRight += col.width ?? 0;
            } else {
                this.setLeftProp(col.field, sum);
                sum += col.width ?? 0;
            }
        });

        if (this.dataColumns.hasGroupedColumns) {
            this.renderGroups();
        }
    }
}

export type OrderedColumnPolyfill = {
    field: string,
    order: number,
    pinned?: 'left' | 'right' | null,
    title: string,
    hide?: boolean,
    parentGroupId?: string,
    element?: HTMLElement,
    width?: number,
    left?: number,
}

class CachedColumn {
    get type(): 'column' { return 'column'; }

    get left() { return this.elements[0]?.style.left ? +this.elements[0]?.style.left : this.elements[0]?.style.left; }
    set left(pVal) {
        this.elements.forEach(el => {
            el.style.left = pVal ? `${pVal}px` : '';
            el.dataset.left = `${pVal}`;
        });
    }

    id: string;
    elements: HTMLElement[];
    width: number;
    constructor(pId: string, pElements: HTMLElement[], pWidth: number) {
        this.id = pId;
        this.elements = pElements;
        this.width = pWidth;
    } 

    addClass(pClassName: string) {
        this.elements.forEach(el => el.classList.add(pClassName));
    }
    removeClass(pClassName: string) {
        this.elements.forEach(el => el.classList.remove(pClassName));
    }
}

class CachedColumnGroup {
    get type(): 'group' { return 'group'; }

    id: string;
    columns: Array<CachedColumn | CachedColumnGroup>;

    get left(): string | number { return this.columns[0]?.left; }
    set left(pVal) {
        let widthSum = 0;
        this.columns.forEach((col) => {
            col.left = +pVal + widthSum;
            widthSum += col.width;
        });
    }

    get width(): number {
        return this.columns.reduce((w, current) => w + current.width, 0 as number);
    }

    constructor(pId: string, pColumns: CachedColumnGroup['columns']) {
        this.id = pId;
        this.columns = pColumns;
    }

    addClass(pClassName: string) {
        this.columns.forEach(col => col.addClass(pClassName));
    }
    removeClass(pClassName: string) {
        this.columns.forEach(col => col.removeClass(pClassName));
    }
}